








import { Vue, Component } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import { RewardStores } from '@/store/rewards';
import RewardForm from '@/components/rewards/RewardForm.vue';

@Component({
  components: {
    Page,
    BackButtonLayout,
    RewardForm,
  },
})
export default class EditRewards extends Vue {
  rewardStore = RewardStores;

  get reward() {
    return this.rewardStore?.reward;
  }

  mounted() {
    this.rewardStore.getReward(Number(this.$route.params.id));
  }

  handleSubmit(payload: any) {
    this.rewardStore.updateReward({
      id: Number(this.$route.params.id),
      ...payload,
    });
  }
}
